import { PageProps, graphql } from "gatsby";
import React, { useEffect } from "react";

import CategoryPreview from "@shared/categoryPreview";
import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { HeroCarousel } from "@global";
import IconButtons from "@shared/sub/iconButton";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import Layout from "@shared/layout";
import OurRange from "@shared/ourRange";
import { Query } from "@graphql-types";
import RecipePreviewCarousel from "@components/recipes/recipePreviewCarousel";
import SEO from "@shared/seo";
import WhatsNew from "@shared/whatsNew";
import queryString from "query-string";
import { useStore } from "@state/store";

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const HomepageTemplate = (props: Props) => {
  //Check url params
  useEffect(() => {
    let parsedSearch = queryString.parse(location.search);

    if (parsedSearch && parsedSearch.signup) {
      //Set global search state
      useStore.setState({
        visibility: {
          loginDrawerVisible: true,
          cartDrawerVisible: false,
        },
      });
    }
  }, []);

  const homeQuery = props.data.allSanityHomePage.nodes[0];
  if (homeQuery == null) return;

  return (
    <>
      {homeQuery.seo && <SEO seoData={homeQuery.seo} />}
      <Layout>
        <Container flexDirection="column">
          {homeQuery.hero && homeQuery.additionalHeros ? (
            <HeroCarousel
              hero={homeQuery.hero}
              additionalHeros={homeQuery.additionalHeros}
              homeHero
            />
          ) : (
            <Hero homeHero sanityHero={homeQuery.hero} />
          )}
          {homeQuery.iconButtons && (
            <IconButtons data={homeQuery.iconButtons} />
          )}
          {homeQuery.ourStory && (
            <ImageTextBlockWithCarousel
              singleBlockData={homeQuery.ourStory}
              margin="80px auto"
            />
          )}
          {homeQuery.ourRange && <OurRange data={homeQuery.ourRange} />}
          {homeQuery.featuredRecipes && (
            <RecipePreviewCarousel recipes={homeQuery.featuredRecipes} />
          )}
          {homeQuery.gifting && (
            <ImageTextBlockWithCarousel
              singleBlockData={homeQuery.gifting.giftingBlock}
              customHeight="650px"
              margin="120px auto"
            />
          )}
          {homeQuery &&
            homeQuery.featuredProducts &&
            homeQuery.featuredProducts.length > 0 && (
              <CategoryPreview
                isFeatured
                products={homeQuery.featuredProducts}
                margin="0 0 0 5%"
                tabletMargin="0 0 0 5%"
              />
            )}
          {homeQuery &&
            homeQuery.featuredProductsAu &&
            homeQuery.featuredProductsAu.length > 0 && (
              <CategoryPreview
                isFeatured
                products={homeQuery.featuredProductsAu}
                tabletMargin="50px auto"
              />
            )}
          {homeQuery.whatsNew && (
            <Container
              flexDirection="column"
              width="90%"
              margin="150px auto 0 auto"
              tabletMargin="100px auto 0 auto"
            >
              <WhatsNew data={homeQuery.whatsNew} noContainerMargin />
            </Container>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query allHomepageQuery($iban: String) {
    allSanityHomePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        ourStory {
          ...sanityImageTextBlock
        }
        ourRange {
          _key
          heading
          title
          backgroundImage {
            ...sanityImageHero
          }
          collections {
            main {
              title
              slug {
                current
              }
              hero {
                videoUrl {
                  image {
                    ...sanityImageHero
                  }
                }
              }
            }
          }
        }
        featuredRecipes {
          _id
          info {
            ...sanityRecipeInfo
          }
        }
        gifting {
          ...sanityGiftingLanding
        }
        featuredProducts {
          ...productPreviewBasic
        }
        featuredProductsAu {
          ...productPreviewBasicAu
        }
        whatsNew {
          ...sanityWhatsNew
        }
        iconButtons {
          _key
          name
          icon {
            ...sanityImageFullWidth
          }
          link {
            ...sanityLink
          }
        }
      }
    }
  }
`;
