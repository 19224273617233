import { Image, Link } from "@global";
import { Maybe, SanityCollection, SanityCollectionRange } from "@graphql-types";
import { Button, Container, HeroH3, P, StyledOverlay } from "@util/standard";
import { navigate } from "gatsby";
import React, { useState } from "react";

interface Props {
  data?: Maybe<SanityCollectionRange> | undefined;
}

const OurRange = (props: Props) => {
  const { data } = props;

  if (data == null || data.collections.length === 0) {
    return null;
  }

  const [backgroundImage, setBackgroundImage] = useState(
    data.backgroundImage?.asset?.gatsbyImageData
  );

  const handleBackgroundHover = (e: SanityCollection) => {
    setBackgroundImage(e.main?.hero?.videoUrl?.image?.asset?.gatsbyImageData);
  };

  return (
    <Container>
      <Image isBackground imageData={backgroundImage}>
        <Container
          width="95vw"
          height="700px"
          mobileDimensions={{ height: "500px" }}
          margin="0px"
          display="flex"
          flexDirection="row"
        >
          <StyledOverlay height="100%" />
          <Container
            width="340px"
            margin="auto auto 75px 130px"
            display="flex"
            flexDirection="column"
            zIndex={20}
            mobileWidth="80%"
            mobileMargin="auto"
          >
            <HeroH3>{data.heading?.toUpperCase()}</HeroH3>
            <P fontSize={30} color="white" margin="0px" bold>
              {data.title}
            </P>
            <Container flexDirection="column" margin="26px 0px">
              {data.collections &&
                data.collections.map(collection => {
                  return (
                    <Link
                      key={collection?.main?.slug?.current}
                      linktext={collection?.main.title}
                      url={collection?.main?.slug?.current}
                      internallink
                      fontSize={30}
                      color="white"
                      opacity={0.5}
                      hoverOpacity={1}
                      onMouseEnter={e => handleBackgroundHover(collection)}
                    />
                  );
                })}
            </Container>
            <Button
              color="white"
              border="1.5px solid #fff"
              hoverColour="merlin"
              width="250px"
              onClick={() => navigate("/shop")}
            >
              VIEW ALL
            </Button>
          </Container>
        </Container>
      </Image>
    </Container>
  );
};

export default OurRange;
