import { Container, P } from "@util/standard";
import { Maybe, SanityIconButtons } from "@graphql-types";

import { Image } from "@global";
import { Link } from "gatsby";
import React from "react";

interface Props {
  data: Maybe<SanityIconButtons>[];
}

const IconButtons = ({ data }: Props) => {
  if (data == null) return null;

  return (
    <Container
      width="60%"
      margin="100px auto 0px auto"
      isMobileColumn
      justifyContent="center"
    >
      {data.map((item, index) => {
        return (
          <Link to={item?.link?.url}>
            <Container
              position="relative"
              width="200px"
              margin="0 10px"
              flexDirection="column"
            >
              <Image imageData={item?.icon?.asset?.gatsbyImageData} />
              <P fontSize={18} margin="15px auto" color="merlin">
                {item?.name}
              </P>
            </Container>
          </Link>
        );
      })}
    </Container>
  );
};

export default IconButtons;
